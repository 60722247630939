<template>
  <div style="margin-top: 10px; margin-bottom: 10px;">
    <div
      ref="multiselect"
      :class="[
        'form-item',
        `form-item-${stype}`,
        {
          valid: valid,
          invalid: invalid,
          'multiselect-is-filter': isFilter
        }
      ]"
    >
      <div v-if="label !== undefined" class="label">{{ label }}</div>
      <div
        :class="[
          'form-item-multiselect',
          {
            'multiselect-is-focus': isFocus,
            'multiselect-is-open': isOpen,
            'multiselect-is-disabled': disabled
          }
        ]"
        tabindex="-1"
        @focus="focusHandler"
        @blur="focusHandler"
      >
        <div @click="toggleHandler" class="form-item-multiselect__control">
          <div class="form-item-multiselect__container">
            <div
              v-if="!items.length > 0"
              class="form-item-multiselect__placeholder"
            >
              {{ placeholder || "Не выбрано"}}
            </div>
            <ul class="form-item-multiselect__items">
              <li v-for="item in items.filter(Boolean)" :key="item.value" class="form-item-multiselect__items-item">
                {{ item.label }}
                <button class="form-item-multiselect__items-btn-delete" @click="(event) => deleteItem(item.value, event)">
                </button>
              </li>
            </ul>
          </div>
          <div class="form-item-multiselect__indicators">
            <span></span>
          </div>
        </div>
        <input
          v-if="isOpen && filteredOptions !== null"
          class="form-item-multiselect__searchInOptions"
          name="searchInOptions"
          placeholder="Поиск по названию.."
          :value="searchInOptions"
          @input="(evt) => {this.searchInOptions = evt.target.value}"
        />
        <transition :name="`form-item-multiselect__menu_${optionsPosition}`">
          <div
            v-if="isOpen && filteredOptions !== null"
            :class="[
              'form-item-multiselect__menu',
              `form-item-multiselect__menu_${optionsPosition}`
            ]"
            :style="{
              'max-height': maxHeightMenu
            }"
          >
            <div
              v-for="option in filteredOptions"
              :key="option.value"
              @click="addItem(option)"
              class="form-item-multiselect__menu-option"
            >
              {{ option.label }}
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="form-item form-item-max">
      <div style="width: 100%">
        <div
          v-for="error,index in errors"
          :key={index}
          class="error-massage"
        >
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    values: {
      type: Array,
      default() {
        return []
      },
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    touched: {
      type: Boolean,
    },
    shouldValidate: {
      type: Boolean,
    },
    valid: {
      type: Boolean,
    }, 
    placeholder: {
      type: String,
    },
    stype: {
      type: String,
      default: 'normal',
    },
    maxHeightMenu: {
      type: String | Number,
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    isFilter: {
      type: Boolean,
    }
  },
  data() {
    return {
      isOpen: false,
      isFocus: false,
      items: this.values.map(value => this.options.find(o => o.value == value)),
      optionsPosition: 'bottom',
      searchInOptions: ""
    }
  },
  computed: {
    invalid() {
      return !this.valid && this.touched && this.shouldValidate;
    },

    filteredOptions() {
      const reg = new RegExp('\^'+this.searchInOptions, 'i');
      return [...this.options].filter((option) => reg.test(option.label));
    }
  },

  methods: {
    focusHandler(evt) {
      if (
        (evt.type === "focus" && !this.disabled) ||
        evt.relatedTarget && evt.relatedTarget.name === "searchInOptions"
      ) {
        this.isFocus = true;
      } else {
        this.isFocus = false;
        this.isOpen = false;

        this.$emit("blur", {
          type: "blur",
          target: { name: this.name, value: this.items.map(i => i.value) }
        });
      }
    },

    addItem(option) {
      if (!this.items.find((i) => i.value === option.value)) {
        this.items.push({...option});
        this.$emit("change", {
          type: "change",
          target: { name: this.name, value: this.items.map(i => i.value), type: 'multiselect' }
        });
      }
    },

    deleteItem(value, event) {
      if (event && event.stopPropagation) {
        event.stopPropagation(); 
      }

      const index = this.items.findIndex((i) => i.value === value );
      this.items.splice(index, 1);
      this.$emit("change", {
          type: "change",
          target: { name: this.name, value: this.items.map(i => i.value), type: 'multiselect' }
        });
    },

    toggleHandler() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      }
    },
  },

  watch: {
    isOpen: function() {
      if (this.$refs.multiselect && this.isOpen) {
        const elem = this.$refs.multiselect;

        const { top, height } = elem.getBoundingClientRect();

        if (window.innerHeight - top + height < (this.maxHeightMenu || 250)) {
          this.optionsPosition = "top";
        } else {
          this.optionsPosition = "bottom";
        }
      }
    },
    values: function() {
      this.items = this.values
        .map(value => this.options.find(o => o.value == value));
    },
  },

}
</script>

<style lang="scss">
.form-item {
  .label {
    font-size: 14px;
    color: #808080;
    width: calc(50% - 5px);

    & + .form-item-multiselect {
      width: calc(50% - 5px);
      margin-left: 10px;
    }
  }

  &.multiselect-is-filter {
      .label {
        width: calc(35% - 5px);

        & + .form-item-multiselect {
          width: calc(65% - 5px);
          margin-left: 10px;
        }
      }
    }

  &-min {
    .label {
      width: calc(50% - 5px);

      & + .form-item-multiselect {
        width: 25%;
        margin-left: 10px;
      }
    }
  }

  &.invalid {
    .form-item-multiselect__control {
      border: 1px solid #ff7b7b;
      background: #fff2f2;

      &:focus,
      &:hover {
        border: 1px solid #ff7b7b;
      }
    }
  }
}

.form-item-multiselect {
  width: 100%;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  &.multiselect-is-focus {
    .form-item-multiselect__control {
      outline: 0;
      outline-offset: 0;
      border: 1px solid #6f61e9;
    }
  }

  &.multiselect-is-open {
    z-index: 5;

    .form-item-multiselect__control {
      outline: 0;
      outline-offset: 0;
      border: 1px solid #6f61e9;
    }
  }
  &.multiselect-is-disabled {
    .form-item-multiselect__control {
      background: #f6f6f8;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid transparent;
      }
    }
  }

  label {
    display: block;
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    margin-bottom: 5px;
  }

  &__menu {
    position: absolute;
    width: 100%;
    max-height: 250px;
    box-shadow: 0px 16px 24px rgba(221, 218, 235, 0.36);
    border-radius: 4px;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: -1;

    &_bottom {
      bottom: -49px;
      transform: translateY(100%);

      &-enter-active,
      &-leave-active {
        transform: translateY(100%);
        transition: 0.4s cubic-bezier(0.19, 0.36, 0.49, 1.1);
        transition-property: opacity, transform;
      }

      &-enter,
      &-leave-to {
        transform: translateY(calc(100% + 13px));
        opacity: 0;
      }
    }

    &_top {
      top: -10px;
      transform: translateY(-100%);

      &-enter-active,
      &-leave-active {
        transform: translateY(-100%);
        transition: 0.4s cubic-bezier(0.19, 0.36, 0.49, 1.1);
        transition-property: opacity, transform;
      }

      &-enter,
      &-leave-to {
        transform: translateY(calc(-100% - 13px));
        opacity: 0;
      }
    }

    &-option {
      padding: 8px 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: normal;
      color: #000;
      background: rgba(255, 255, 255, 0);
      transition: 0.3s ease;
      transition-property: color, background;
      cursor: pointer;

      &:hover {
        background: rgba(111, 97, 233, 0.1);
        color: #6f61e9;
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 5px;
    }
  }

  &__items {
    padding: 0;
    margin: 7px 0;
    list-style-type: none;

    &-item {
      display: flex;
      align-items: center;
      padding: 8px 0px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: normal;
      color: #000;
      background: rgba(255, 255, 255, 0);
      transition: 0.3s ease;
      transition-property: color, background;
      cursor: pointer;
    }

    &-btn-delete {
      width: 12px;
      height: 12px;
      margin-left: 5px;
      border: none;
      background-color: #cccbd9;
      cursor: pointer;
  
      -webkit-mask: url("/img/icon/close.svg") no-repeat center;
      mask: url("/img/icon/close.svg") no-repeat center;      

      &:hover {
        background-color: #6f61e9;
      }
    }
  }

  &__control {
    display: flex;
    width: 100%;
    background: #fff;
    border: 1px solid #e0dfee;
    height: auto;
    min-height: 50px;
    border-radius: 4px;
    transition: 0.25s ease;
    transition-property: border;

    &:hover {
      border: 1px solid #6f61e9;
    }
  }

  &__indicators {
    display: flex;
    width: 25px;
    flex: 0 0 25px;
    padding: 0 5px;

    span {
      position: relative;
      transform: translateY(-3px);
      margin: auto;

      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border: solid #cccbd9;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  &__container {
    width: 100%;
    max-width: calc(100% - 25px);
    height: auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
  }

  &__value {
    font-size: 14px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__single-value,
  &__placeholder {
    color: #333;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__searchInOptions {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    color: #333;
    padding: 0 12px;
    transition: 0.3s ease;
    background: #fff;
    border: 1px solid #e0dfee;
    transition-property: border;

    &:focus,
    &:hover {
      border: 1px solid #6f61e9;
    }
  }
}

.ie {
  .form-item-multiselect__indicators {
    width: 15px;
    flex: 0 0 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      margin: 0;
    }
  }
}

.error-massage {
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: #f5383b;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 51%;
}
</style>
