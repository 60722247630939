export enum FNS_STATUS_IDS {
  NoNeed = 0,
  CheckStarted = 1,
  NotFound = 2,
  Found = 3,
  CheckError = 4,
  IgnoredByPartner = 5,
  FoundAndRevoked = 6,
  Cancelled = 7,
}

export const FNS_STATUSES = {
  NoNeed: {
    id: FNS_STATUS_IDS.NoNeed,
    title: "Проверка существования сертификата в ФНС для заявки не требуется",
    color: ""
  },
  CheckStarted: {
    id: FNS_STATUS_IDS.CheckStarted,
    title: "Отправлена проверка в ФНС",
    color: "rgb(173,173,29)"
  },
  NotFound: {
    id: FNS_STATUS_IDS.NotFound,
    title: "В ФНС нет сертификата с данными заявки",
    color: ""
  },
  Found: {
    id: FNS_STATUS_IDS.Found,
    title: "Найдены сертификаты ФНС",
    color: "#f5383b"
  },
  CheckError: {
    id: FNS_STATUS_IDS.CheckError,
    title: "Ошибка проверки сертификата в ФНС",
    color: "#f5383b"
  },
  IgnoredByPartner: {
    id: FNS_STATUS_IDS.IgnoredByPartner,
    title: "Филиал проигнорировал существование сертификата в ФНС",
    color: ""
  },
  FoundAndRevoked: {
    id: FNS_STATUS_IDS.FoundAndRevoked,
    title: "Найдены сертификаты ФНС, но есть заявление на отзыв",
    color: "#f5383b"
  },
  Cancelled: {
    id: FNS_STATUS_IDS.Cancelled,
    title: "Проверки отменены, выпуск в ФНС принудительно разрешен",
    color: ""
  },
};
