




























































import Vue from "vue";
export default Vue.extend({
 name: "FilterInfoString",
 props: {
   filterInfoString: {
     type: Object,
     required: true
   }
 },
 computed: {
   getIsVisible() {
     let isVisible = false;
     Object.keys(this.filterInfoString).forEach((name: string) => {
       isVisible = isVisible || !!this.filterInfoString[name];
     })
     return isVisible;
   }
 }
})
