<template>
  <div class="search-and-filter-wrapper">
    <Input
      class="search"
      stype="top"
      placeholder="Поиск"
      name="search"
      :value="getSearchValue(pageName)"
      @change="(evt) => onChangeSearch({pageName, evt})"
      @keyup.enter="() => fetchList(pageName)"
    />
    <div class="filter" ref="filter">
      <Button
        @click="
          () => {
            toggleFilter({isOpen: !isOpenFilter, pageName});
          }
        "
        class="button-main"
        color="purple-ghost"
      >
        <IconBase width="16" height="16" viewBox="0 0 12 12">
          <Setings />
        </IconBase>
        <div class="filter__red-marker" :hidden="!isActiveFilter(pageName)" />
      </Button>
      <transition name="fade">
        <div v-if="isOpenFilter" class="filter-views-wrap">
          <div class="filter-views">
            <div
              v-for="group, groupIndex in Object.values(getFilterControls(pageName)).filter(({disabled}) => !disabled)"
              :class="`filter-item ${contentClassName(group.name)}`"
              :key="groupIndex"
            >
              <div class="filter-item__title">
                <span>{{group.title}}</span>
              </div>
              <div class="filter-item__content">
                <Checkbox
                  v-for="control, index in Object.values(group.controls).filter(c => c.type === 'checkbox')"
                  :key="index"
                  :name="control.name"
                  :label="control.label"
                  :checked="control.value"
                  :disabled="group.disabled"
                  @change-event="(evt) => onChange({pageName, groupName: group.name, evt})"
                />
                <DateFilterItem
                  :isVisible="group.name.startsWith('date')"
                  :dateName="group.name"
                  :filterControls="group.controls"
                  :disabled="group.disabled"
                  @inputHandler="(evt) => onChange({pageName, groupName: group.name, evt})"
                />
                <Select
                  :class="control.name"
                  v-for="control, index in Object.values(group.controls).filter(c => c.type === 'select')"
                  :key="index"
                  :name="control.name"
                  :valid="true"
                  :shouldValidate="false"
                  :hasSearch="control.hasSearch"
                  :value="control.value"
                  :disabled="group.disabled"
                  :options="Array.isArray(control.options) && control.options.length ? control.options : filterOptions[control.name]"
                  @change="(evt) => onChange({pageName, groupName: group.name, evt})"
                />
                <MultiSelect
                  v-for="control, index in Object.values(group.controls).filter(c => c.type === 'multiselect')"
                  :key="index"
                  :label="control.label"
                  :name="control.name"
                  :isFilter="true"
                  :disabled="control.disabled || group.disabled"
                  :touched="control.touched"
                  :values="control.value"
                  :options="Array.isArray(control.options) && control.options.length ? control.options : filterOptions[control.name]"
                  @change="(evt) => onChange({pageName, groupName: group.name, evt})"
                  @blur="(evt) => onChange({pageName, groupName: group.name, evt})"
                />
              </div>
            </div>
            <div class="filter-item presets">
              <div class="filter-item__title">
                <span>{{"Выбрать готовый пресет"}}</span>
              </div>
              <div class="filter-item__content">
                <Select
                  name="filter-presets"
                  :valid="true"
                  :shouldValidate="false"
                  :value="currentPreset"
                  :options="getPrimitivePresetOptions(pageName).map(o => ({...o, deletable: o.value}))"
                  :deleteOptionHandler="(presetName) => deletePresetHandler(presetName)"
                  @change="(evt) => onChangePreset({presetName: evt.target.value, pageName})"
                />
              </div>
            </div>
          </div>

          <div class="button-group">
            <button class="clear" @click="() => clearFilterHandler(pageName)">Очистить</button>
            <button class="apply" @click="() => applyFilter(pageName)">Применить</button>
          </div>
        </div>
      </transition>
    </div>
    <div ref="modalAddFilterPreset">
      <ModalWithField
        name="modal-add-filter-preset"
        width="600px"
        height="287px"
        title="Создать новый пресет фильтра"
        field="input"
        label="Введите имя нового пресета"
        invalidityInfo="Имя пресета не должно повторяться на страницах заявок и предзаявок, а также содержать более 32 символов"
        :rule="(value) => !isPresetNameExist(value) && value.length < 33"
        @onClickOk="(presetName) => addNewPreset({presetName, pageName})"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

import Button from "../UI/Button.vue";
import Input from "../UI/form/Input.vue";
import IconBase from "../UI/IconBase.vue";
import Setings from "../UI/icon/Setings.vue";
import Checkbox from "../UI/form/Checkbox.vue";
import MultiSelect from "../UI/form/MultiSelect.vue";
import Select from "../UI/form/Select.vue";
import ModalWithField from "@/components/ModalWithField.vue";
import DateFilterItem from "@/components/filter/DateFilterItem.vue";
import { PAGE_NAMES } from "@/store/modules/filter/filter.constants";

export default Vue.extend({
  name: "FilterSearch",

  props: {
    pageName: {
      type: String,
      required: true
    },
  },

  components: {
    Button,
    IconBase,
    Setings,
    Checkbox,
    Input,
    Select,
    MultiSelect,
    ModalWithField,
    DateFilterItem,
  },

  computed: {
    ...mapState("filterState", [
      "isOpenFilter",
      "filterOptions",
      "currentPreset",
      "presetOptions",
      "preappFilterControls"
    ]),
    ...mapGetters("filterState", [
      "getSearchValue",
      "getFilterControls",
      "getPrimitivePresetOptions",
      "isActiveFilter"
    ]),

    contentClassName() {
      return (groupName) => {
        if (groupName.startsWith("date")) return "date";
        return groupName;
      }
    },

    isPresetNameExist() {
      return (presetName) => {
         return this.presetOptions.find(o => o.label === presetName);
      }
    }
  },

  methods: {
    ...mapActions("filterState", [
      "inputHandler",
      "clearFilterHandler",
      "toggleFilter",
      "applyFilter",
      "onChangeSearch",
      "fetchList",
      "onChangePreset",
      "addNewPreset",
      "initFilterPresets",
      "deletePreset",
      "getProducts",
    ]),

    onChange({pageName, groupName, evt}) {
      this.inputHandler({pageName, groupName, evt});
      if (evt.target.name === "statusAppFormed") {
        this.togglePeriodIssue(evt);
      }
      if (evt.target.name.startsWith("dateIssue")) {
        this.toggleStatusAppFormed();
      }
    },

    toggleStatusAppFormed() {
      const isFilled =
        !!this.preappFilterControls.dateIssue.controls.dateIssueFrom.value
        || !!this.preappFilterControls.dateIssue.controls.dateIssueTo.value;
      const evt = {target: {
        type: "checkbox",
        name: "statusAppFormed",
        checked: isFilled
      }};
      this.inputHandler({pageName: "preapp", groupName: "status", evt});
    },

    togglePeriodIssue(evt) {
      if(!evt.target.checked) {
        const evt1 = {target: {type: "date", name: "dateIssueFrom", value: ""}};
        const evt2 = {target: {type: "date", name: "dateIssueTo", value: ""}};
        this.inputHandler({pageName: "preapp", groupName: "dateIssue", evt: evt1});
        this.inputHandler({pageName: "preapp", groupName: "dateIssue", evt: evt2});
      }
    },

    onBlurFilter(evt) {
      const filter = this.$refs.filter;
      const addPresetModal = this.$refs.modalAddFilterPreset;
      // somewhy .contains doesnt work with <i> in datepicker
      if (!filter.contains(evt.target) && evt.target.tagName !== "I" && !addPresetModal.contains(evt.target)) {
          this.toggleFilter({isOpen: false, pageName: this.pageName});
        }
    },

    deletePresetHandler(presetName) {
      this.$modal.show("dialog", {
        title: "ВНИМАНИЕ",
        text: "Вы действительно хотите удалить пресет фильтра<br />" + `"${presetName}"?`,
        buttons: [
          {title: "Отмена"},
          {
            title: "Продолжить",
            handler: () => {
              this.deletePreset({presetName});
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    },
  },

  mounted() {
    window.addEventListener("click", this.onBlurFilter);
    this.initFilterPresets(this.pageName);

    if (this.pageName === PAGE_NAMES['preapp']) {
      this.getProducts(this.pageName);
    }
  },

  destroyed() {
    window.removeEventListener("click", this.onBlurFilter);
  },

  beforeDestroy() {
    this.toggleFilter({isOpen: false, pageName: this.pageName});
  }
})
</script>
<style lang="scss">
  .search-and-filter-wrapper {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 15px;
    }
  }

  .filter {
    position: relative;
    z-index: 11;
    & + a,
    & + div,
    & + button {
      margin-left: 15px;
    }
    .button-main {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
      }
    }

    .vdp-datepicker__calendar {
      left: 0;
      bottom: 0;
      transform: translateY(100%);
    }

    .form-item-date {
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -6px;
        width: 12px;
        height: 13.33px;
        opacity: 0.8;
        background: url("/img/icon/calendar.svg") no-repeat center;
      }

      input {
        padding-right: 30px;
      }
    }

    .form-item.search {
      .input-wrap {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 15px;
          margin-top: -6px;
          opacity: 1;
          width: 12px;
          height: 12px;
          background: url("/img/icon/search.svg") no-repeat center;
        }
      }

      input {
        padding-right: 35px;
      }
    }

    &-views {
      display: grid;
      gap: 20px;
      grid-template-rows: repeat(4, auto);
    }

    &-item {
      width: 310px;
      &.applicant {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &.status {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 4;
      }
      &.issuerType {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 4;
      }
      &.identification {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 4;
      }
      &.date {
        grid-column-start: 2;
        grid-column-end: 3;
      }
      &.paymentStatus {
        grid-column-start: 2;
        grid-column-end: 3;
      }
      &.productId {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &.identificationKind {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &.companyGroups {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      &.other {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
      }
      &.presets {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 4;
        grid-row-end: 4;
      }

      &__title {
        color: #000;
        margin-bottom: 10px;
      }

      &__content {
        display: flex;
        flex-direction: column;
      }
    }
    .filter-item-date {
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          width: 47%;
        }
      }
    }
    .button-group {
      button {
        width: 50%;
        background: transparent;
        height: 60px;
        font-weight: 500;
        font-size: 13px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        border: 1px solid #f6f6f8;
        border-bottom: none;
        transition: all 0.3s ease;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }

        &.apply {
          color: #6f61e9;
          &:hover {
            box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
            text-shadow: 0 1px 1px rgba(111, 97, 233, 0.25);
          }
        }

        &.clear {
          color: #f5383b;
          &:hover {
            box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
            text-shadow: 0 1px 1px rgba(245, 56, 59, 0.25);
          }
        }
      }
    }

    &-views-wrap {
      position: absolute;
      transform: translateY(100%);
      width: auto;
      bottom: -8px;
      right: 0px;
      background: linear-gradient(0deg, #fff, #fff);
      box-shadow: 0px 8px 24px #c5c2d1;
      border-radius: 12px;

      &.fade-enter-active,
      &.fade-leave-active {
        transition: all 0.5s;
      }

      &.fade-enter,
      &.fade-leave-to {
        opacity: 0;
        transform: translateY(115%);
      }
    }
    &-views {
      padding: 25px;

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          .form-item-checkbox {
            display: block;
            label {
              width: 100%;
            }
          }
        }
      }
    }

    &__red-marker {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: #DD0A35;
      opacity: 0.8;
    }
  }
</style>
