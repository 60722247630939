










































































































































import Vue from "vue";
import IconBase from "@/components/UI/IconBase.vue";
import Comment from "@/components/UI/icon/Comment.vue";
import Copy from "@/components/UI/icon/Copy.vue";
import Comp from "@/components/UI/icon/Comp.vue";
import Usb from "@/components/UI/icon/Usb.vue";
import Archive from "@/components/UI/icon/Archive.vue";
import PanelSMEV from "@/components/UI/table/PanelSMEV.vue";
import TableStatusFNS from "@/components/UI/table/TableStatusFNS.vue";
import TableRefreshButton from "@/components/UI/table/TableRefreshButton.vue"

export default Vue.extend({
  name: "TableRow",

  components: {
    IconBase,
    Comment,
    Copy,
    Comp,
    Usb,
    Archive,
    PanelSMEV,
    TableStatusFNS,
    TableRefreshButton,
  },

  props: {
    items: {
      type: Array,
      required: true
    }, 
    isTHead: {
      type: Boolean,
      default: false
    },
    cellsWidth: {
      type: Array,
      required: true
    },
    cloneable: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  data() {
    return {
      activeTooltipComment: false,
      activeTooltipManagerComment: false
    }
  }, 

  methods: {
    showComment(text: string) {
      this.$modal.show("dialog", {
        title: "Комментарий администратора",
        text,
        buttons: [
          {
            title: "Закрыть",
            default: true,
            handler: () => {
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    },
    showManagerComment(text: string) {
      this.$modal.show("dialog", {
        title: "Комментарий менеджера",
        text,
        buttons: [
          {
            title: "Закрыть",
            default: true,
            handler: () => {
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    }
  },
})
