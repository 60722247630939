<template>
  <div class="table-refresh-button" :style="styled">
    <div v-if="isLoading" class="table-refresh-button__loader">
      <span class="sand-clock-icon">&#10710;</span>
    </div>
    <button
      v-else
      type="button"
      @mouseenter="() => setIsTooltipVisible(true)"
      @mouseleave="() => setIsTooltipVisible(false)"
      @click.prevent="onClickHandler"
    >
      &#8635;
    </button>
    <transition name="fade" v-if="tooltipText">
      <div v-if="isTooltipVisible" class="table-refresh-button__tooltip">
        <span>{{tooltipText}}</span>
      </div>
    </transition>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default Vue.extend({
    name: 'TableRefreshButton',
    components: {},
    props: {
      styled: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      tooltipText: {
        type: String,
        required: false,
        default: '',
      },
      values: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      onClick: {
        type: Function,
        required: true
      },
    },
    data() {
      return {
        isTooltipVisible: false,
        isLoading: false,
      }
    },
    methods: {
      setIsTooltipVisible(value) {
        this.isTooltipVisible = value;
      },

      setIsLoading(value) {
        this.isLoading = value;
      },

      onClickHandler: async function() {
        this.setIsTooltipVisible(false);
        this.setIsLoading(true);
        await this.onClick();
        this.setIsLoading(false);
      }
    },
  })
</script>

<style lang="scss">
  .table-refresh-button {
    position: relative;
    width: 24px;

    button {
      height: 24px;
      width: 24px;
      text-align: center;
      border-radius: 12px;
      color: rgb(255, 255, 255);
      background-color: rgb(111, 97, 233);
      border: 1px solid rgb(111, 97, 233);
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: rgb(111, 97, 233);
        background-color: rgb(255, 255, 255);
        font-weight: 700;
      }
    }
  }

  .table-refresh-button__loader {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    border: 1px solid rgb(111, 97, 233);
    padding-left: 1px;
    color: rgb(111, 97, 233);
    background-color: rgb(255, 255, 255);
  }

  .table-refresh-button__tooltip {
    position: absolute;
    top: 50%;
    right: -15px;
    z-index: 3;
    transform: translate(100%, -50%);
    padding: 10px 15px 10px 10px;
    max-width: 180px;
    min-width: 140px;
    background: #6f61e9;
    opacity: 1;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 13px;
    text-align: left;
    line-height: 17px;
    color: #fff;
    min-width: 120px;

    &.fade-enter-active,
    &.fade-leave-active {
      transition: 0.2s ease;
      transition-property: opacity, transform;
    }
    &.fade-enter,
    &.fade-leave-to {
      transform: translate(120%, -50%);
      opacity: 0;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-left: -7px;
      margin-top: -9px;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 8px solid #6f61e9;
    }
  }

  .sand-clock-icon {
    display: inline-block;
    padding: 0 5px 0 5px;
    font-size: 18px;
    animation:spin 1s linear infinite;
  }
  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }
  @-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
  }
  @keyframes spin { 
    100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
    } 
  }
</style>
