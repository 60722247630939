





import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  name: "Table",
  methods: {
    ...mapActions("tableState", [
      "startListenTableWidth",
      "startListenCellsWidth"
    ])
  },
  mounted() {
    this.startListenTableWidth();
    this.startListenCellsWidth();
  }
  
})
