<template>
  <modal 
    :name="name"
    styles="border-radius: 12px;"
    :width="width"
    :height="height"
    :adaptive="true"
    :clickToClose="false"
  >
    <div class="modal-with-fields-wrapper">
      <div class="modal-with-fields-title">{{title}}</div>
      <InputRadio
        v-for="field in fields.filter(f => f.type === 'radio')"
        :key="field.name"
        :name="`${field.type}__${field.name}`"
        :label="field.label"
        :valid="true"
        :shouldValidate="false"
        :value="values[field.name]"
        :val="field.options"
        :disabled="field.disabled"
        @change="onChange"
      />
      <TextArea
        v-for="field in fields.filter(f => f.type === 'textarea')"
        :key="field.name"
        :name="`${field.type}__${field.name}`"
        :label="field.label"
        :valid="true"
        :shouldValidate="false"
        :value="values[field.name]"
        :disabled="field.disabled"
        @change="onChange"
      />
      <Select
        v-for="field in fields.filter(f => f.type === 'select')"
        :key="field.name"
        :name="`${field.type}__${field.name}`"
        :label="field.label"
        :valid="true"
        :shouldValidate="false"
        :value="values[field.name]"
        :options="field.options"
        :disabled="field.disabled"
        :className="field.className"
        @change="onChange"
      />
      <Input
        v-for="field in fields.filter(f => f.type === 'input')"
        :key="field.name"
        :name="`${field.type}__${field.name}`"
        :label="field.label"
        :mask="field.mask"
        :valid="true"
        :shouldValidate="false"
        :value="values[field.name]"
        :disabled="field.disabled"
        @change="onChange"
      />
      <Checkbox
        v-for="field in fields.filter((f) => f.type === 'switch')"
        :key="field.name"
        :name="`${field.type}__${field.name}`"
        :label="field.label"
        :valid="true"
        :shouldValidate="false"
        :checked="values[field.name]"
        :disabled="field.disabled"
        @change-event="onChange"
      />
      <div
        v-for="field in files"
        :key="field.name"
        class="modal-with-fields__file-input"
      >
        <label>{{field.label}}</label>
        <ItemDocument
          style="width: calc(50% - 5px)"
          :name="`${field.type}__${field.name}`"
          :title="field.title"
          :descr="field.descr"
          :accept="field.accept"
          :maxFiles="1"
          :type="'doc'"
          :disabled="false"
          :isUploadable="true"
          :isWatchable="false"
          :valid="field.isValid"
          :touched="field.isTouched"
          :shouldValidate="!!field.rules"
          :isFilled="!!field.files.length"
          @change="inputFile"
        />
      </div>
      <div class="modal-with-fields-description">
        <span>{{description}}</span>
      </div>
      <div class="modal-with-fields-buttons">
        <Button @click="handleCancel">Отмена</Button>
        <Button @click="handleOk" :disabled="isOkDisabled">Ok</Button>
      </div>
    </div>
  </modal>
</template>

<script>
  import Vue from "vue";
  import {template} from "lodash";

  import Button from "@/components/UI/Button.vue";
  import Select from "@/components/UI/form/Select.vue";
  import TextArea from "@/components/UI/form/TextArea.vue";
  import InputRadio from "@/components/UI/form/InputRadio.vue";
  import Input from "@/components/UI/form/Input.vue";
  import Checkbox from '@/components/UI/form/Checkbox.vue';
  import ItemDocument from "@/components/ItemDocument.vue";

  import {extname} from "@/utils";
  import {validationFile} from "@/services";

  const fileDefault = {
    isValid: false,
    isTouched: false,
    files: [],
    errors: [],
    errorMessage: {
      size: "Размер файла не должен превышать 15 Мб.",
      type: "Неверный формат.",
    },
  };

  export default Vue.extend({
    name: "ModalWithFields",

    components: {Button, InputRadio, TextArea, Select, Input, Checkbox, ItemDocument},

    props: {
      name: {
        type: String,
        required: true
      },
      width: {
        type: String,
        required: true
      },
      height: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: false,
        default: ""
      },
      fields: {
        type: Array,
        required: true
      },
    },

    data() {
      return {
        values: this.fields.reduce((obj, item) => ({
          ...obj,
          [item.name]: item.defaultValue
        }), {}),
        files: this.fields
          .filter((f) => f.type === 'file')
          .map(item => ({
            ...item,
            ...JSON.parse(JSON.stringify(fileDefault)),
            rules: {
              size: 15728640,
              type: item.acceptedMimes,
            },
          })),
      }
    },

    computed: {
      isOkDisabled() {
        const isValids = Object.keys(this.values).reduce((result, name) => {
          let isRequired;
          let isValid;
          const value = this.values[name];
          const file = this.files.find(item => item.name.replace(/^\w+__/, '') === name);
          if (file) {
            isValid = (file.required ? !!file.files.length : true) && !file.errors.length;
          } else {
            isRequired = this.getRules[name] ? this.getRules[name].isRequired : true;
            isValid = !!(value === 0 || value) || !isRequired;
          }

          return [...result, isValid];
        }, []);

        const isValuesEmpty = !Object.values(this.values).filter(v => (Array.isArray(v) ? v.length : (v === 0 || !!v))).length;
        return isValuesEmpty || isValids.includes(false);
      },

      getRules() {
        return this.fields.reduce((obj, item) => ({
          ...obj,
          [item.name]: item.rule
        }), {})
      }
    },

    methods: {
      handleOk() {
        this.$emit("onClickOk", {...this.values});
        this.cleanValues();
        this.clearFiles();
        this.$modal.hide(this.name);
      },

      handleCancel() {
        this.$emit("onClickCancel", {...this.values});
        this.cleanValues();
        this.clearFiles();
        this.$modal.hide(this.name);
      },

      onChange(evt) {
        const {value, checked, name} = evt.target;
        const nameWithoutType = name.replace(/^\w+__/, '');

        if (name.startsWith('radio') || name.startsWith('switch')) {
          this.values = {...this. values, [nameWithoutType]: checked};
        }
        if (name.startsWith('input') || name.startsWith('textarea') || name.startsWith('select')) {
          this.values = {...this. values, [nameWithoutType]: value};
        } 
      },

      cleanValues() {
        this.values = this.fields.reduce((obj, item) => ({
          ...obj,
          [item.name]: item.defaultValue
        }), {})
      },

      clearFiles() {
        this.files = this.fields
          .filter((f) => f.type === 'file')
          .map(item => ({
            ...item,
            ...JSON.parse(JSON.stringify(fileDefault)),
            rules: {
              size: 15728640,
              type: item.acceptedMimes,
            },
          }));
      },

      inputFile(evt) {
        evt.preventDefault();
        const errors = [];
        const compiled = template(
          `<ul>
            <% _.forEach( names, function(name) { %>
              <li> <%- name %> </li>
            <% }) %>
          </ul>`
        );
        const {name, files}  = evt.target;
        const fieldName = name.replace(/^\w+__/, '');
        const index = this.files.findIndex(item => item.name === fieldName);
        const control = {...this.files[index]};
        control.isTouched = true;

        if (files[0]) {
          const namesFiles = [];
          const isMaxFiles = files.length > (control.maxFiles ? control.maxFiles : 1);

          if (!isMaxFiles) {
            const filesArr = isMaxFiles ? control.maxFiles : files.length;
            for (let i = 0; i < filesArr; i++) {
              const file = files[i];
              const errorsMessage = validationFile(file, {
                rules: control.rules,
                errorMessage: control.errorMessage,
              });

              if (errorsMessage.length) {
                errorsMessage.forEach((error) => {
                  if (!~errors.indexOf(error)) {
                    errors.push(error);
                  }
                });
              } else {
                namesFiles.push(file.name.replace(extname(file.name), ""));
                control.files.push(file);
              }
            }

            control.descr = namesFiles.length
              ? compiled({names: namesFiles})
              : errors.length
                ? compiled({names: errors})
                : '';
            control.errors = [...errors];
          } else {
            control.files = [];
            control.descr = compiled({names: [`Максимальное количество файлов ${control.maxFiles}`]});
            control.errors = [`Максимальное количество файлов ${control.maxFiles}`];
          }
        }

        control.isValid = control.files && !control.errors.length;

        this.files.splice(index, 1, control);
        this.values = {...this.values, [fieldName]: control.files};
      },
    },
  })
</script>

<style lang="scss">
  .modal-with-fields-wrapper {
    width: 100%;
    padding: 45px;
    text-align: center;

    .form-item-radio {
      width: 50%;
    }
  }
  .modal-with-fields-title {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    padding-bottom: 25px;
  }
  .modal-with-fields-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
  }
  .modal-with-fields-description {
    text-align: justify;
    padding: 25px 0 10px 0;
  }
  .modal-with-fields__file-input {
    width: 100%;
    display: flex;
    gap: 10px;
    margin-top: 10px;

    >label {
      width: calc(50% - 5px);
      text-align: left;
      font-size: 14px;
      color: #808080;
      margin: auto 0;
    }
  }
</style>
