









































import Vue from "vue";
import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";

import { DATE_FORMAT } from "@/constants";

export default Vue.extend({
  name: "DateFilterItem",

  components: {
    Datepicker
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    dateName: {
      type: String,
      required: true
    },
    filterControls: {
      type: Object,
      required: true
    },
  },

  computed: {
    getName() {
      return {
        from: this.dateName + "From",
        to: this.dateName + "To",
      }
    }, 
    getLanguage() {
      return ru;
    },
    getFormat() {
      return DATE_FORMAT;
    }
  },

  methods: {
    dateInputHandler(name: string, value: string) {
      const evt = {
        target: {type: "date", name, value}
      };

      this.$emit("inputHandler", evt);
    }, 
    checkIfFilled() {
      const isFilled = !!this.filterControls[this.dateName + "From"].value
        || !!this.filterControls[this.dateName + "To"].value;

      this.$emit("handleIfFilled", isFilled);
    }
  }
})
