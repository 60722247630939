var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('div',{ref:"multiselect",class:[
      'form-item',
      ("form-item-" + _vm.stype),
      {
        valid: _vm.valid,
        invalid: _vm.invalid,
        'multiselect-is-filter': _vm.isFilter
      }
    ]},[(_vm.label !== undefined)?_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:[
        'form-item-multiselect',
        {
          'multiselect-is-focus': _vm.isFocus,
          'multiselect-is-open': _vm.isOpen,
          'multiselect-is-disabled': _vm.disabled
        }
      ],attrs:{"tabindex":"-1"},on:{"focus":_vm.focusHandler,"blur":_vm.focusHandler}},[_c('div',{staticClass:"form-item-multiselect__control",on:{"click":_vm.toggleHandler}},[_c('div',{staticClass:"form-item-multiselect__container"},[(!_vm.items.length > 0)?_c('div',{staticClass:"form-item-multiselect__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder || "Не выбрано")+" ")]):_vm._e(),_c('ul',{staticClass:"form-item-multiselect__items"},_vm._l((_vm.items.filter(Boolean)),function(item){return _c('li',{key:item.value,staticClass:"form-item-multiselect__items-item"},[_vm._v(" "+_vm._s(item.label)+" "),_c('button',{staticClass:"form-item-multiselect__items-btn-delete",on:{"click":function (event) { return _vm.deleteItem(item.value, event); }}})])}),0)]),_vm._m(0)]),(_vm.isOpen && _vm.filteredOptions !== null)?_c('input',{staticClass:"form-item-multiselect__searchInOptions",attrs:{"name":"searchInOptions","placeholder":"Поиск по названию.."},domProps:{"value":_vm.searchInOptions},on:{"input":function (evt) {this$1.searchInOptions = evt.target.value}}}):_vm._e(),_c('transition',{attrs:{"name":("form-item-multiselect__menu_" + _vm.optionsPosition)}},[(_vm.isOpen && _vm.filteredOptions !== null)?_c('div',{class:[
            'form-item-multiselect__menu',
            ("form-item-multiselect__menu_" + _vm.optionsPosition)
          ],style:({
            'max-height': _vm.maxHeightMenu
          })},_vm._l((_vm.filteredOptions),function(option){return _c('div',{key:option.value,staticClass:"form-item-multiselect__menu-option",on:{"click":function($event){return _vm.addItem(option)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0):_vm._e()])],1)]),_c('div',{staticClass:"form-item form-item-max"},[_c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.errors),function(error,index){return _c('div',{key:{index: index},staticClass:"error-massage"},[_vm._v(" "+_vm._s(error)+" ")])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-item-multiselect__indicators"},[_c('span')])}]

export { render, staticRenderFns }